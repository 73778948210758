<template>
  <div class="d-flex flex-column">
    <v-row v-if="userEntities.length" class="tabs-toolbar align-self-end justify-end align-center mx-4">
      <!-- Sort -->
      <v-chip
        class="mx-2"
        color="white"
        text-color="secundary"
        @click="sortDesc = !sortDesc"
      >
        <v-icon class="mr-1" v-text="'sort_by_alpha'" />
        {{ $t('common.alphabetical') }}
      </v-chip>
      <!-- Search -->
      <v-text-field
        v-model="search"
        class="search flex-grow-0 mb-10 mb-sm-0 w-100 w-sm-auto"
        :label="$t('common.searchByName')"
        prepend-inner-icon="search"
        tag="span"
        outlined
        dense
        hide-details
      />
    </v-row>
    <!-- Tabs -->
    <v-tabs v-model="tab" color="secondary">
      <v-tabs-slider color="primary" />
      <v-tab v-for="{ id, name } in tabs" :key="id" class="text-capitalize" v-text="name" />
    </v-tabs>
    <v-divider />
    <!-- Content -->
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="{ id, content } in tabs" :key="id">
        <v-data-iterator
          v-if="content.length"
          class="px-6 py-4"
          :items="content"
          :search="search"
          :sort-by="'name'"
          :sort-desc="sortDesc"
          hide-default-footer
          disable-pagination
        >
          <!-- Cards -->
          <template #default="props">
            <v-row>
              <v-col v-for="item in props.items" :key="item.id" cols="12" sm="6" md="4" lg="4">
                <v-card class="mx-auto" max-width="344"
                        :to="{ name: item.entityType , params: item.entityType === 'cluster-projects' ?
                          { organizationId, clusterId: item.id } : { projectId: item.id } }"
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-title class="headline mb-1" v-text="item.name" />
                      <v-list-item-subtitle v-text="item.cardDescription" />
                    </v-list-item-content>
                  </v-list-item>

                  <v-card-actions class="px-4 pb-4 ma-0">
                    <v-chip label outlined :color="getColor(item.status)" v-text="$t(`project.status.${item.status}`)" />
                    <v-spacer />
                    <v-btn
                      icon color="primary"
                      @click.prevent="item.isFavorite ? removeFavoriteProject({ id: userId, projectId: item.id}) : addFavoriteProject({ id: userId, projectId: item.id})"
                      @mousedown.stop
                    >
                      <v-icon v-text="item.isFavorite ? 'star' : 'star_outline'" />
                    </v-btn>
                    <v-menu v-if="isUserAdmin" bottom left>
                      <template #activator="{ on, attrs }">
                        <v-btn color="primary" icon v-bind="attrs" v-on="on" @click.prevent @mousedown.stop>
                          <v-icon>more_vert</v-icon>
                        </v-btn>
                      </template>
                      <!-- Menu list -->
                      <v-list>
                        <v-list-item @click="createCloneItem(item)">
                          <v-list-item-title>{{ $t('common.duplicate') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="item.status !== 'archived'" @click="archiveItem(item)">
                          <v-list-item-title>{{ $t('common.archive') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-else @click="unarchiveItem(item)">
                          <v-list-item-title>{{ $t('common.unarchive') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="deleteItem(item)">
                          <v-list-item-title>{{ $t('common.delete') }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
        <empty-list v-else :content="$t('organization.sections.projects')" :content-adjetive="tabs[tab] && tabs[tab].name" />
      </v-tab-item>
    </v-tabs-items>

    <!-- Button Create -->
    <v-menu v-if="isGuild && !clusterId && isUserAdmin">
      <template #activator="{ on, attrs }">
        <v-btn color="primary" bottom fab fixed right v-bind="attrs" v-on="on" @click.prevent @mousedown.stop>
          <v-icon v-text="'add'" />
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="isGuild ? showCreateGuildDialog = true : showCreateProjectDialog = true">
          <v-list-item-title>{{ $t('project.createProjectDialog.createProject') }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="showCreateClusterDialog = true">
          <v-list-item-title v-text="isGuild ? $t('cluster.createClusterGuild') : $t('cluster.createCluster')" />
        </v-list-item>
      </v-list>
    </v-menu>
    <div v-else-if="clusterId ? isUserClusterManager : isUserAdmin" class="buttons-create d-flex justify-end px-sm-11">
      <v-btn
        color="primary" bottom fab fixed right
        @click="isGuild ? showCreateGuildDialog = true : showCreateProjectDialog = true"
      >
        <v-icon>add</v-icon>
      </v-btn>
    </div>
    <!-- Dialogs -->
    <create-project-dialog
      v-if="showCreateProjectDialog"
      :organization-id="organizationId"
      @close-dialog="showCreateProjectDialog = false"
      @next="$router.push({ name: 'project', params: { projectId: $event } })"
    />
    <create-cluster-dialog
      v-if="showCreateClusterDialog"
      :organization-id="organizationId" @closeDialog="showCreateClusterDialog = false"
    />
    <create-guild-dialog
      v-if="showCreateGuildDialog"
      :organization-id="organizationId" :cluster-id="clusterId" @closeDialog="showCreateGuildDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ProjectList',
  components: {
    CreateProjectDialog: () => import('@/modules/project/CreateProjectDialog'),
    CreateClusterDialog: () => import('@/modules/cluster/CreateClusterDialog'),
    CreateGuildDialog: () => import('@/modules/guild/CreateGuildDialog'),
    EmptyList: () => import('@/components/EmptyList'),
  },
  props: {
    organizationId: { type: String, required: true },
    clusterId: { type: String, default: null },
  },
  data() {
    return {
      tab: null,
      showCreateProjectDialog: false,
      showCreateClusterDialog: false,
      showCreateGuildDialog: false,
      search: '',
      sortDesc: false,
    }
  },
  computed: {
    ...mapGetters('organization', ['clusters', 'projects', 'isUserAdmin', 'isGuild']),
    ...mapGetters('cluster', ['isUserClusterManager']),
    ...mapGetters({ clusterProjects: 'cluster/projects' }),
    ...mapGetters('user', ['collabProjectIds', 'managerClusterIds', 'collabClusterIds']),
    userId() { return this.$store.state.user.dbData.id },
    userEntities({ projects, clusters, clusterProjects, clusterId }) {
      if (clusterId) {
        const userProjects = this.isUserAdmin ? clusterProjects
          : clusterProjects.filter(project => this.collabProjectIds.includes(project.id)
        || this.managerClusterIds.includes(project.clusterId))
        return userProjects.map(p => ({ ...p, entityType: 'project' }))
      }
      const userClusters = this.isUserAdmin ? clusters : clusters.filter(c => this.collabClusterIds.includes(c.id) || this.managerClusterIds.includes(c.id))
      const userProjects = (this.isUserAdmin ? projects : projects.filter(project => this.collabProjectIds.includes(project.id))).filter(p => !p.clusterId)
      return [...userClusters.map(c => ({ ...c, entityType: 'cluster-projects' })), ...userProjects.map(project => ({ ...project, entityType: 'project' }))]
    },
    tabs({ userEntities }) {
      return [
        {
          id: 'active',
          name: this.$t('filters.active'),
          content: userEntities.filter(project => project.active),
        },
        {
          id: 'favorites',
          name: this.$t('filters.favorites'),
          content: userEntities.filter(project => project.isFavorite),

        },
        {
          id: 'archived',
          name: this.$t('filters.archived'),
          content: userEntities.filter(project => !project.active),
        },
      ]
    },
  },
  methods: {
    ...mapActions('project', ['archive', 'unarchive', 'createClone']),
    ...mapActions('user', ['addFavoriteProject', 'removeFavoriteProject']),
    ...mapActions({ archiveCluster: 'cluster/archive' }),
    ...mapActions({ unArchiveCluster: 'cluster/unArchive' }),
    ...mapActions({ createCloneCluster: 'cluster/createClone' }),
    remove({ project }) {
      const { organizationId } = this
      this.runAsync(() => this.$store.dispatch('project/delete', { organizationId, project }))
    },
    removeCluster({ cluster }) {
      const { organizationId } = this
      this.runAsync(() => this.$store.dispatch('cluster/delete', { organizationId, cluster }))
    },
    getColor(status) {
      return this.$vuetify.theme.themes.light[status] ?? 'red'
    },
    createCloneItem(item) {
      const { organizationId } = this
      if (item.entityType === 'project') this.createClone({ organizationId, projectId: item.id })
      if (item.entityType === 'cluster-projects') this.createCloneCluster({ organizationId, clusterId: item.id })
    },
    archiveItem(item) {
      const { organizationId } = this
      if (item.entityType === 'project') this.archive({ organizationId, project: item })
      if (item.entityType === 'cluster-projects') this.archiveCluster({ organizationId, cluster: item })
    },
    unarchiveItem(item) {
      const { organizationId } = this
      if (item.entityType === 'project') this.unarchive({ organizationId, project: item })
      if (item.entityType === 'cluster-projects') this.unArchiveCluster({ organizationId, cluster: item })
    },
    deleteItem(item) {
      if (item.entityType === 'project') this.$confirm(this.$t('project.confirmDelete')).then(res => res && this.remove({ project: item }))
      if (item.entityType === 'cluster-projects') this.$confirm(this.$t('cluster.confirmDelete')).then(res => res && this.removeCluster({ cluster: item }))
    },
  },
}
</script>
<style lang="scss" scoped>
.buttons-create {
  position: fixed;
  bottom: 16px;
  width: 100%;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    width: calc(100% - 250px);
  }
}

::v-deep .v-window.v-item-group.v-tabs-items {
  margin-bottom: 70px;
}
</style>
